import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';

import axios from "axios";
import { Input, Button, Upload, Select, message, UploadFile } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { useAuth } from './AuthContext';
import config from "../config";
import { IGameUpload, GameMetaInfo } from "../models/game_model";
import { getMyProfile } from "../clients/auth_client";
import { RcFile } from "antd/es/upload";
import { getBase64, removeFields } from "../services/utils";

const { TextArea } = Input;
const { Option } = Select;


export function UploadGame() {
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth();
    const [ gameMetaInfo, setGameMetaInfo ] = useState<GameMetaInfo | null>(null);
    const [ loading, setLoading ] = useState(false);
    const { register, handleSubmit, setValue, watch } = useForm<IGameUpload>();
    const selectedGameType = watch("game_type");

    const selectedFile = watch("image");
    const fileList: UploadFile<RcFile>[] = selectedFile
    ? [{
        uid: "-1",
        name: selectedFile.name, 
        status: "done",
        url: URL.createObjectURL(selectedFile),
        originFileObj: selectedFile as RcFile
    }]
    : [];
    

    useEffect(() => {
        axios.get(`${config.backendUrl}/api/v1/games/metainformation`)
        .then((response) => {
            setGameMetaInfo(response.data);
        })
        .catch((error) => console.error("Ошибка загрузки данных:", error));
    }, []);

    useEffect(() => {
        const fetchMyUser = async () => {
            try {
                const myUser = await getMyProfile();
                if (myUser) {
                    setValue("author_username", myUser.username, { shouldValidate: true });
                }
            } catch (error) {
                console.error("Ошибка при получении профиля:", error);
            }
        };
    
        if (isAuthenticated) {
            fetchMyUser();
        }
    }, [isAuthenticated, setValue]);

    if (!isAuthenticated) {
        navigate("/");
        return null;
    }

    const onSubmit = async (data: IGameUpload) => {
        try {
            setLoading(true);

            const restData = removeFields(data, [
                'min_duration', 
                'max_duration', 
                'min_participants', 
                'max_participants',
                'min_teams',
                'max_teams'
            ]);

            const payload = {
                ...restData,
                image: selectedFile ? await getBase64(selectedFile as RcFile) : null,
                duration: {
                    min_duration: data.min_duration,
                    max_duration: data.max_duration
                },
                participants: {
                    min_participants: data.min_participants,
                    max_participants: data.max_participants
                },
                teams: {
                    min_teams: data.min_teams,
                    max_teams: data.max_teams
                }
            }

            const response = await axios.post(`${config.backendUrl}/api/v1/games/`, payload, {
                headers: { "Content-Type": "application/json" },
            });

            message.success("Игра успешно отправлена на модерацию!");
            console.log(response.data);
        } catch (error) {
            message.error("Ошибка при загрузке игры.");
            console.error(error);
        } finally {
            setLoading(false);
            console.log("Loading set to false");
        }
    };

    return (
        <div className="bg-white px-4 py-8 mx-auto max-w-7xl">
            <h2 className="text-3xl font-bold">Загрузить игру</h2>
            <p className="mt-2 text-gray-600">Заполните информацию о вашей игре и загрузите изображение. <br />После отправки информации - игра должна пройти модерацию.</p>

            <form onSubmit={handleSubmit(onSubmit)} className="mt-8 grid grid-cols-1 gap-y-7 sm:grid-cols-4 lg:grid-cols-3 gap-x-6">
                <input type="hidden" {...register("author_username")} />

                <div>
                    <label className="text-sm font-medium text-gray-500">Название игры</label>
                    <Input 
                        {...register("name", { required: true })} 
                        placeholder="Введите название игры"
                        maxLength={100} 
                        onChange={(e) => setValue("name", e.target.value, { shouldValidate: true })}
                    />
                </div>
                <div className="sm:col-span-2"></div>

                <div>
                    <label className="text-sm font-medium text-gray-500">Краткое описание</label>
                    <TextArea 
                        {...register("short_description", { required: true })} 
                        rows={2} 
                        placeholder="Краткое описание игры"
                        maxLength={250}
                        style={{ whiteSpace: "pre-line" }} 
                        onChange={(e) => setValue("short_description", e.target.value, { shouldValidate: true })}
                    />
                </div>
                <div className="sm:col-span-2"></div>

                <div>
                    <label className="text-sm font-medium text-gray-500">Правила</label>
                    <TextArea 
                        {...register("rules", { required: false })} 
                        rows={4} 
                        placeholder="Правила"
                        maxLength={1000} 
                        onChange={(e) => setValue("rules", e.target.value, { shouldValidate: true })}
                    />
                </div>

                <div>
                    <label className="text-sm font-medium text-gray-500">Требования</label>
                    <TextArea 
                        {...register("requirements", { required: false })} 
                        rows={4} 
                        placeholder="Требования к проведению игры"
                        maxLength={1000} 
                        onChange={(e) => setValue("requirements", e.target.value, { shouldValidate: true })}
                    />
                </div>

                <div className="sm:col-span-2">
                    <label className="text-sm font-medium text-gray-500">Описание</label>
                    <TextArea 
                        {...register("description", { required: false })} 
                        rows={4} 
                        placeholder="Подробное описание игры"
                        maxLength={4000} 
                        onChange={(e) => setValue("description", e.target.value, { shouldValidate: true })}
                    />
                </div>

                <div className="sm:col-span-1"></div>

                <div>
                    <label className="text-sm font-medium text-gray-500">Тип игры (всего {gameMetaInfo?.gameType.length} типов игр)</label><br />
                    <Select 
                        value={selectedGameType} // Устанавливаем текущее значение
                        onChange={(value) => setValue("game_type", value)} // Обновляем значение
                        placeholder="Выберите тип игры"
                        style={{ width: "100%" }}
                    >
                        {gameMetaInfo?.gameType.map((type) => (
                            <Option key={type} value={type}>{type}</Option>
                        ))}
                    </Select>
                </div>

                <div>
                    <label className="text-sm font-medium text-gray-500">Сложность проведения (от {gameMetaInfo?.difficulty.at(0)} до {gameMetaInfo?.difficulty.at(-1)})</label><br />
                    <Input 
                        type="number" 
                        {...register("difficulty", { required: true })} 
                        min={gameMetaInfo?.difficulty.at(0)}
                        max={gameMetaInfo?.difficulty.at(-1)}
                        placeholder="Выберите сложность"
                        onChange={(e) => setValue("difficulty", Number(e.target.value), { shouldValidate: true })} 
                    />
                </div>

                <div className="sm:col-span-1"></div>

                <div>
                    <label className="text-sm font-medium text-gray-500">Число участников в команде (от {gameMetaInfo?.participants.at(0)} до {gameMetaInfo?.participants.at(-1)})</label>
                    <div className="flex space-x-2">
                        <Input
                            type="number"
                            {...register("min_participants", { required: true })} 
                            min={gameMetaInfo?.participants.at(0)}
                            max={gameMetaInfo?.participants.at(-1)}
                            placeholder="Мин."
                            onChange={(e) => setValue("min_participants", Number(e.target.value), { shouldValidate: true })} 
                        />
                        <Input 
                            type="number" 
                            {...register("max_participants", { required: true })} 
                            min={gameMetaInfo?.participants.at(0)}
                            max={gameMetaInfo?.participants.at(-1)} 
                            placeholder="Макс."
                            onChange={(e) => setValue("max_participants", Number(e.target.value), { shouldValidate: true })} 
                        />
                    </div>
                </div>

                <div>
                    <label className="text-sm font-medium text-gray-500">Количество команд (от {gameMetaInfo?.teams.at(0)} до {gameMetaInfo?.teams.at(-1)})</label>
                    <div className="flex space-x-2">
                        <Input 
                            type="number" 
                            {...register("min_teams", { required: true })} 
                            min={gameMetaInfo?.teams.at(0)}
                            max={gameMetaInfo?.teams.at(-1)} 
                            placeholder="Мин."
                            onChange={(e) => setValue("min_teams", Number(e.target.value), { shouldValidate: true })}  
                        />
                        <Input 
                            type="number" 
                            {...register("max_teams", { required: true })} 
                            min={gameMetaInfo?.teams.at(0)}
                            max={gameMetaInfo?.teams.at(-1)} 
                            placeholder="Макс."
                            onChange={(e) => setValue("max_teams", Number(e.target.value), { shouldValidate: true })}  
                        />
                    </div>
                </div>

                <div className="sm:col-span-1"></div>

                <div>
                    <label className="text-sm font-medium text-gray-500">Минимальное число судей (от {gameMetaInfo?.judges.at(0)} до {gameMetaInfo?.judges.at(-1)})</label>
                    <Input 
                        type="number" 
                        {...register("min_judges", { required: true })} 
                        min={gameMetaInfo?.judges.at(0)}
                        max={gameMetaInfo?.judges.at(-1)} 
                        placeholder="Введите число судей"
                        onChange={(e) => setValue("min_judges", Number(e.target.value), { shouldValidate: true })} 
                    />
                </div>

                <div>
                    <label className="text-sm font-medium text-gray-500">Продолжительность (в минутах, от {gameMetaInfo?.duration.at(0)} мин. до {gameMetaInfo?.duration.at(-1)} мин.)</label>
                    <div className="flex space-x-2">
                        <Input 
                            type="number" {...register("min_duration", 
                            { required: true })} 
                            min={gameMetaInfo?.duration.at(0)}
                            max={gameMetaInfo?.duration.at(-1)} 
                            placeholder="Мин."
                            onChange={(e) => setValue("min_duration", Number(e.target.value), { shouldValidate: true })} 
                        />
                        <Input 
                            type="number" {...register("max_duration", 
                            { required: true })} 
                            min={gameMetaInfo?.duration.at(0)}
                            max={gameMetaInfo?.duration.at(-1)}
                            placeholder="Макс." 
                            onChange={(e) => setValue("max_duration", Number(e.target.value), { shouldValidate: true })} 
                        />
                    </div>
                </div>

                <div className="sm:col-span-2">
                    <label className="text-sm font-medium text-gray-500">Загрузить изображение</label><br />
                    <Upload
                        accept="image/*"
                        beforeUpload={(file) => {
                            if (!file.type.startsWith("image/")) {
                                message.error("Можно загружать только изображения!");
                                return false;
                            }
                            setValue("image", file);
                            return false;
                        }}
                        fileList={fileList}
                        maxCount={1}
                        onRemove={() => setValue("image", null)}
                    >
                        <Button icon={<UploadOutlined />}>Выберите изображение</Button>
                    </Upload>
                </div>

                <div className="sm:col-span-2">
                    <Button type="primary" htmlType="submit" loading={loading} block>
                        Загрузить игру
                    </Button>
                </div>
            </form>
        </div>
    );
}